import * as React from "react";
import { Link } from "gatsby";
import Logo from "../../images/logo_22.svg";
import "./notFound.scss";

const NotFound = () => {
  return (
    <div id="page-not-found">
      <div className="h-100 d-flex flex-column align-items-center justify-content-center">
        <div className="mb-5 mx-3">
          <img alt="logo" src={Logo} />
        </div>
        <div className="mb-5 mx-3">
          <h1>
            <span className="highlight">Whoops</span>, the requested page could
            not be found...
          </h1>
        </div>
        <Link className="btn btn-primary btn-lg" to="/">Home</Link>
      </div>
    </div>
  );
};

export default NotFound;
