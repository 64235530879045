import * as React from "react"
import SEO from "../components/seo/Seo"
import Layout from "../components/layout/Layout"
import NotFound from "../components/notFound/NotFound";


const NotFoundPage = () => {
  return (
    <Layout showFooter={false}>
      <SEO title="Not found" />
      <NotFound/>  
    </Layout>
  )
}

export default NotFoundPage
